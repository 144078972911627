import * as React from "react";
import { Link } from "gatsby";
import {
  ClockIcon,
  ComputerDesktopIcon,
  ShoppingCartIcon,
  RectangleStackIcon,
  PencilSquareIcon,
  Bars3BottomLeftIcon,
} from "@heroicons/react/24/outline";
import { Loader } from "../Loader/Loader";

export const CBanner = (props) => {
  const course = props.values.courseObj;
  const sponsor = course?.sponsor;
  const children = course?.children;
  const modules = course?.modules;
  const sections = course?.sections;
  const { allowed, isAdmin, submitLoader } = props.values;
  const { addToCart, openApplication } = props.functions;

  return (
    <div
      className="bg-path bg-cover lg:mb-[40px] relative"
      style={{ backgroundImage: `url(${course?.imgUrl})` }}
    >
      <div className={"absolute top-0 left-0 w-full h-full"}>
        <div className="before:bg-black before:absolute before:top-0 before:bottom-0 before:left-0 before:right-0 before:opacity-50"></div>
      </div>
      <section className="container px-[15px] mx-auto pt-[40px] lg:pt-[110px] pb-[40px] lg:pb-0">
        <div className="relative z-1 flex flex-col lg:flex-row lg:items-center lg:grid pt-[40px] gap-4 lg:gap-10 lg:grid-cols-10">
          <div className="order-2 lg:order-1 lg:col-span-6">
            <h2 className="ff-cg--semibold text-white text-[34px] lg:text-[50px] leading-none mb-[10px]">
              {course?.title}
            </h2>
            <p className="ff-cg--extralight text-white text-[16px] lg:text-[30px] leading-none">
              {course?.description}
            </p>
            <div className="flex items-center flex-wrap mt-[60px]">
              <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                <ComputerDesktopIcon className="h-4 w-4 mr-[6px]" />
                {course ? (
                  <span className="ff-cg--semibold text-[12px]">
                    {course.type.name === "Learning Path"
                      ? "Certificate"
                      : course.type.name}
                  </span>
                ) : (
                  <span className="ff-cg--semibold text-[12px]">
                    {course?.type === "Learning Path"
                      ? "Certificate"
                      : course?.type}
                  </span>
                )}
              </span>
              <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                <ClockIcon className="h-4 w-4 mr-[6px]" />
                <span className="ff-cg--semibold text-[12px]">
                  {course?.duration}
                </span>
              </span>
              {!sections ? (
                <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                  <Bars3BottomLeftIcon className="h-4 w-4 mr-[6px]" />
                  <span className="ff-cg--semibold text-[12px] bg-red-400">
                    Available Dates
                  </span>
                </span>
              ) : (
                ""
              )}
              {sponsor?.title === "MindEdge" ? (
                <>
                  {children && children.length ? (
                    <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                      <RectangleStackIcon className="h-4 w-4 mr-[6px]" />
                      <span className="ff-cg--semibold text-[12px]">
                        {children.length} Courses
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {modules && modules.length ? (
                    <span className="flex items-center text-white border border-white rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                      <RectangleStackIcon className="h-4 w-4 mr-[6px]" />
                      <span className="ff-cg--semibold text-[12px]">
                        {modules.length} Courses
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col lg:flex-row items-center mt-[30px] mb-[60px]">
              <button className="w-full md:w-fit flex flex-col items-center justify-between border solid border-[#fdbf38] py-[5px] px-[16px] rounded-2xl mt-[20px] xl:mr-4">
                <span className="ff-cg--bold leading-none text-[28px] text-[#fdbf38]">
                  {Number(course?.price).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    currencyDisplay: "symbol",
                  })}
                </span>
                <span className="ff-cg--semibold text-[12px] text-white leading-none">
                  Price
                </span>
              </button>
              {sections?.length===0 ? (
                (sponsor?.title == "Springboard" || course?.uuid ===process.env.SPECIAL_APPLICATION_COURSE  || 
                  sponsor?.title == "MedCerts") &&
                !isAdmin ? (
                  <button
                    className="w-full md:w-fit flex items-center justify-between bg-[#fdbf38] px-[16px] min-h-[52px] rounded-2xl mt-[20px] "
                    onClick={() => openApplication()}
                  >
                    <span className="ff-cg--semibold mr-[20px]">
                      Apply Now{" "}
                    </span>
                    <PencilSquareIcon className="h-6 w-6" />
                  </button>
                ) : (
                  <>
                    {allowed ? (
                      <button
                        className={
                          "w-full md:w-fit flex items-center justify-between bg-[#fdbf38] px-[16px] min-h-[52px] rounded-2xl mt-[20px] " +
                          (submitLoader ? "!bg-[#da1a32]" : "")
                        }
                        onClick={() => addToCart(course)}
                      >
                        <span className="ff-cg--semibold mr-[20px]">
                          {submitLoader ? "Adding to Cart" : "Add to Cart"}
                        </span>
                        {submitLoader ? (
                          <Loader
                            open={submitLoader}
                            dimension={"8"}
                            min={"8"}
                            wrapClass={""}
                          />
                        ) : (
                          <ShoppingCartIcon className="h-6 w-6" />
                        )}
                      </button>
                    ) : (
                      <>
                        {!isAdmin ? (
                          <button className="w-1/2 lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[16px] rounded-2xl mt-[20px]">
                            <Link to={"/learning"} className="flex">
                              <span className="ff-cg--semibold mr-[20px]">
                                Go to your Learning
                              </span>
                              <ShoppingCartIcon className="h-6 w-6" />
                            </Link>
                          </button>
                        ) : (
                          <button
                            className={
                              "w-1/2 lg:w-fit flex items-center justify-between bg-[#fdbf38] px-[16px] min-h-[52px] rounded-2xl mt-[20px] " +
                              (submitLoader ? "!bg-[#da1a32]" : "")
                            }
                            onClick={() => addToCart(course)}
                          >
                            <span className="ff-cg--semibold mr-[20px]">
                              {submitLoader ? "Adding to Cart" : "Add to Cart"}
                            </span>
                            {submitLoader ? (
                              <Loader
                                open={submitLoader}
                                dimension={"8"}
                                min={"8"}
                                wrapClass={""}
                              />
                            ) : (
                              <ShoppingCartIcon className="h-6 w-6" />
                            )}
                          </button>
                        )}
                      </>
                    )}
                  </>
                )
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="order-1 lg:order-2 mb-6 lg:mb-0 lg:col-span-4 top-0 h-full flex justify-start lg:relative lg:items-center lg:top-[-55px] lg:justify-end">
            <div className="flex z-100 top-0 flex-col items-start lg:items-center">
              <p className="ff-cg--semibold text-white">In Partnership With:</p>
              <img
                className="w-auto object-cover h-12 mt-2"
                src={sponsor?.imgUrl}
                alt={sponsor?.name}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
