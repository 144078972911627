import { useEffect, useState } from "react";

import VisitorAPI from "visitorapi";
export const useVisitorApi = () => {
  const [visitorData, setVisitorData] = useState({}); // store the whole json

  const [ipAddress, setIpAddress] = useState("");

  const [countryCode, setCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [cityLatLong, setCityLatLong] = useState(""); // city center latitude and longitude

  const [currencies, setCurrencies] = useState([]); // currencies data is an array
  const [languages, setLanguages] = useState([]); // languages data is an array

  const [browser, setBrowser] = useState("");
  const [browserVersion, setBrowserVersion] = useState("");

  const [deviceBrand, setDeviceBrand] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [deviceFamily, setDeviceFamily] = useState("");

  const [os, setOs] = useState("");
  const [osVersion, setOsVersion] = useState("");

  useEffect(() => {
    VisitorAPI(process.env.VISITOR_API, (data: any) => {
      setVisitorData(data);
      setIpAddress(data.ipAddress);
      setCountryCode(data.countryCode);
      setCountryName(data.countryName);
      setState(data.region);
      setCity(data.city);
      setCityLatLong(data.cityLatLong);
      setCurrencies(data.currencies);
      setLanguages(data.languages);
      setBrowser(data.browser);
      setBrowserVersion(data.browserVersion);
      setDeviceBrand(data.deviceBrand);
      setDeviceModel(data.deviceModel);
      setDeviceFamily(data.deviceFamily);
      setOs(data.os);
      setOsVersion(data.osVersion);
    });
  }, []);
  return {
    visitorData,
    ipAddress,
    countryCode,
    countryName,
    state,
    city,
    cityLatLong,
    currencies,
    languages,
    browser,
    browserVersion,
    deviceBrand,
    deviceModel,
    deviceFamily,
    os,
    osVersion,
  };
};
