import * as React from "react";

export const AdditionalInfo = (props: any) => {
  const { courseObj } = props;
  const ascm = (
    <section>
      <div className="container px-[15px] mx-auto mb-[80px]">
        <div className="mb-6 lg:mb-0 bg-[#222222] flex items-center rounded-2xl box">
          <div className="p-[40px]">
            <h3 className="ff-cg--semibold text-[#fdbf3c] text-[15px] leading-none mb-2 uppercase">
              Additional Info
            </h3>
            <p className="text-white text-base text-[14px] leading-normal">
              {courseObj?.detail?.additional_info}
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const shrm = (
    <section>
      <div className="container px-[15px] mx-auto mb-[80px]">
        <div className="mb-6 lg:mb-0 bg-[#222222] flex items-center rounded-2xl box row-span-1 col-start-1 col-span-4">
          <div className="p-[40px]">
            {courseObj?.detail?.prerequisites ? (
              <>
                <h3 className="ff-cg--semibold text-[#fdbf3c] text-[15px] leading-none mb-2 uppercase">
                  Prerequisites
                </h3>
                <p className="text-white text-base text-[14px] leading-normal mb-8">
                  {courseObj?.detail?.prerequisites}
                </p>
              </>
            ) : (
              ""
            )}
            <h3 className="ff-cg--semibold text-[#fdbf3c] text-[15px] leading-none mb-2">
              Disclaimers
            </h3>
            <p className="text-white text-base text-[14px] leading-normal">
              This course of study will in no way guarantee or assure success on
              the SHRM-CP or SHRM-SCP exam. Students must use the most recent
              edition of the SHRM educational products for this course and may
              not use outdated materials.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  const medCerts = (
    <section>
      <div className="container px-[15px] mx-auto mb-[80px]">
        <div className="flex flex-col lg:grid overflow-hidden lg:grid-cols-6 lg:grid-rows-2 lg:gap-6">
          <div className="order-1 lg:order-none mb-6 lg:mb-0 bg-[#ffffff] flex items-center shadow-lg rounded-2xl box  row-span-1 col-start-1 col-span-4">
            <div className="p-[30px]">
              <h3 className="ff-cg--bold text-[30px] leading-none mb-4">
                Additional Information
              </h3>
              <p className="text-base text-[18px] leading-normal mb-4">
                Graduates of this program are eligible for the Certified
                Clinical Medical Assistant (CCMA) and Certified Medical
                Administrative Assistant (CMAA) exams, issued by the National
                Healthcareer Association (NHA). MedCerts covers the cost of all
                exams.
              </p>
              <p className="text-base text-[18px] leading-normal">
                Additional student services include: dedicated Student Success
                Advisors, tailored support based on performance & behavior, exam
                preparation process, and 1 year post-program access to MedCerts
                Career Center & Career Coaches.
              </p>
            </div>
          </div>
          <div className="order-3 lg:order-none mb-6 lg:mb-0 bg-[#da1a33] rounded-2xl p-[30px] box row-span-2 col-span-2">
            <div>
              <h3 className="ff-cg--semibold text-white text-[30px] leading-none mb-2">
                Refund Policy
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                MedCerts offers withdrawals and refunds if you decide you no
                longer want to continue in your MedCerts program after
                enrolling. You must meet the below criteria to be issued a
                refund:
              </p>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                • You have the right to cancel this agreement entirely either
                prior to or up to 21 days after the program start date.
              </p>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                • When written notice of program cancellation is received, a
                100% refund will be issued for any tuition and fees paid to
                date. The written notice must be sent with a date (either in
                electronic format or postmarked and mailed) on or before the
                21st day after your official start date.
              </p>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                • Before issuing any refund, all program materials and supplies
                sent to you must be returned. You are responsible for all
                shipping costs to return materials. You have 30 days to return
                MedCerts materials. If materials are not received within 30 days
                of Cancellation your refund is considered void and will not be
                issued.
              </p>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                • Refunds will be issued within 30 days following MedCerts’
                receipt of all returned program materials and supplies.
              </p>
            </div>
          </div>
          <div className="order-2 lg:order-none mb-6 lg:mb-0 bg-[#222222] flex items-center rounded-2xl box row-span-1 col-start-1 col-span-4">
            <div className="p-[30px]">
              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Prerequistes
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-8">
                High School Diploma or GED.
              </p>

              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Refund Policy
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                MedCerts provides options for the cost of this program. Pay in
                full, spread out payments, use employer tuition reimbursements
                or government aid programs like MyCAA, Army COOL, Workforce
                training grants or nonprofit funding.
              </p>
              <p className="text-white text-base text-[18px] leading-normal">
                Talk with an Education Consultant to pick the perfect payment
                plan, from paying in full to installments with interest rates
                from 0-10%.
              </p>
              <button className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[30px] rounded-2xl mt-[30px]">
                <span className="ff-cg--bold text-[18px]">Learn More</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const springboard = (
    <section>
      <div className="container px-[15px] mx-auto mb-[80px]">
        <div className="flex flex-col lg:grid overflow-hidden lg:grid-cols-6 lg:grid-rows-2 lg:gap-6">
          <div className="order-1 lg:order-none mb-6 lg:mb-0 bg-[#ffffff] flex items-center shadow-lg rounded-2xl box  row-span-1 col-start-1 col-span-4">
            <div className="p-[30px]">
              <h3 className="ff-cg--bold text-[30px] leading-none mb-4">
                Additional Info
              </h3>
              <p className="text-base text-[18px] leading-normal">
                Springboard provides optional career material at strategic
                points both in the curriculum as well as via calls with expert
                career support coaches. Once you complete the course, you'll
                have lifetime access to your Springboard account, including the
                online community and office hours.
              </p>
            </div>
          </div>
          <div className="order-3 lg:order-none mb-6 lg:mb-0 bg-[#da1a33] rounded-2xl p-[30px] box row-span-2 col-span-2">
            <div>
              <h3 className="ff-cg--semibold text-white text-[30px] leading-none mb-2">
                Payment Options
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-8">
                Student can apply for a Ioan with Springboard's partner Climb
                Credit, and if approved, use it to pay for tuition. Learn about
                the application process on Climb Credit's website.
              </p>
              <p className="text-white text-base text-[18px] leading-normal mb-8">
                Students who pay upfront will receive a 14% discount on tution
                bringing the price to $8,500.
              </p>
              <p className="text-white text-base text-[18px] leading-normal">
                Students may pay month-to-month with the total being
                $1,650/month.
              </p>
              <button className="w-full lg:w-fit flex items-center justify-between bg-[#fdbf38] py-[14px] px-[30px] rounded-2xl mt-[30px]">
                <span className="ff-cg--bold text-[18px]">Learn More</span>
              </button>
            </div>
          </div>
          <div className="order-2 lg:order-none mb-6 lg:mb-0 bg-[#222222] flex items-center rounded-2xl box row-span-1 col-start-1 col-span-4">
            <div className="p-[30px]">
              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Prerequistes
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-8">
                This bootcamp is for the students who demostrate an intermediate
                aptitude toward critical thinking and problem solving and have
                an interest in building their analytical skills.
              </p>

              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Refund Policy
              </h3>
              <p className="text-white text-base text-[18px] leading-normal">
                Get a full refund within 7 days if you're not happy with the
                course.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const certWise = (
    <section>
      <div className="container px-[15px] mx-auto mb-[80px]">
        <div className="flex flex-col lg:grid overflow-hidden lg:grid-cols-6 lg:grid-rows-2 lg:gap-6">
          <div className="order-1 lg:order-none mb-6 lg:mb-0 bg-[#ffffff] flex items-center shadow-lg rounded-2xl box  row-span-1 col-start-1 col-span-6">
            <div className="p-[30px]">
              <h3 className="ff-cg--bold text-[30px] leading-none mb-4">
                Additional Information
              </h3>
              <p className="text-base text-[18px] leading-normal mb-6">
                {courseObj?.detail?.additional_info}
              </p>

              <h3 className="ff-cg--bold text-[30px] leading-none mb-2">
                Refund Policy
              </h3>
              <p className="text-base text-[18px] leading-normal mb-4">
                No refunds will be given after the start of the course
                (materials have been ordered & received).
              </p>
            </div>
          </div>
          <div className="order-2 lg:order-none mb-6 lg:mb-0 bg-[#222222] flex items-center rounded-2xl box row-span-1 col-start-1 col-span-6">
            <div className="p-[30px]">
              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Prerequistes
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-8">
                {courseObj?.detail?.prerequisites}
              </p>

              <h3 className="ff-cg--semibold text-[#fdbf3c] text-[30px] leading-none mb-2">
                Payment Options
              </h3>
              <p className="text-white text-base text-[18px] leading-normal mb-4">
                Federal Financial Aid, USM Tuition Benefits and partial payment
                plans are not available for this course.
              </p>
              <p className="text-white text-base text-[18px] leading-normal">
                If you are a military servicemember, please check with your
                Education Services Officer (ESO) to find out if this course can
                be covered by Tuition Assistance under current military rules
                and regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const config: any = {
    Springboard: springboard,
    MedCerts: medCerts,
    ASCM: courseObj?.detail?.additional_info ? ascm : "",
    SHRM: shrm,
    CertWise: certWise,
  };

  return config[props.title] ?? "";
};
