import * as React from "react";
import {
  ClockIcon,
  BookOpenIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";

export const Detail = (props: any) => {
  let response: JSX.Element;
  const course = props.courseObj;
  const sponsor = course?.sponsor;
  const { creditsList, modules } = props;

  const mindEdge = (
    <section>
      <div className="container lg:px-[15px] mx-auto pt-[40px] lg:pt-[30px] lg:pb-[20px]">
        <div className="col-span-12 bg-[#222222] lg:rounded-2xl">
          <div className="lg:flex relative lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
            <div className="w-full lg:w-1/3 p-4">
              <h3 className="ff-cg--semibold text-[#fdbf3c] flex items-center text-[12px] lg:text-[14px] leading-none mb-2">
                <ClockIcon className="h-6 w-6 mr-2" /> ACCESS TIME
              </h3>
              <p className="ff-cg--bold text-white text-base text-[40px] lg:text-[50px] leading-normal mb-8 uppercase">
                {course?.access}
              </p>
              <h3 className="ff-cg--semibold text-[#fdbf3c] flex items-center text-[12px] lg:text-[14px] leading-none mb-2">
                <BookOpenIcon className="h-6 w-6 mr-2" /> CREDITS
              </h3>
              {creditsList &&
                creditsList.map((item: any, index: number) => {
                  return (
                    <p
                      className="text-white text-base flex items-center text-[18px] lg:text-[24px] leading-normal"
                      key={index}
                    >
                      <CheckCircleIcon className="h-6 w-6 mr-2" /> {item}
                    </p>
                  );
                })}
            </div>
            {course ? (
              <>
                <div className="w-full lg:w-1/3 p-4">
                  <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                    PREREQUISITES
                  </h3>
                  <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal mb-8">
                    {course.detail?.prerequisites}
                  </p>
                  <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                    REFUND POLICY
                  </h3>
                  <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal">
                    You may request a refound up to 7 days from the purchase
                    date. The registration fee will only be refunded if less
                    than 10% of the course has been completed. Completion
                    percentage can be viewed on the Course Progress page from
                    within the course.
                  </p>
                </div>
                <div className="w-full lg:w-1/3 p-4">
                  <h3 className="ff-cg--semibold text-[#fdbf3c] text-[12px] lg:text-[14px] leading-none mb-2">
                    ADDITIONAL INFO
                  </h3>
                  <p className="text-white text-base text-[16px] lg:text-[18px] leading-normal mb-4">
                    {course.detail?.additional_info}
                  </p>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );

  const certWise = (
    <section className="bg-[#222222] mb-[40px]">
      <div className="container px-[15px] lg:px-[60px] mx-auto pt-[30px] pb-[30px] lg:pb-[40px]">
        <h3 className="text-white text-[30px] lg:text-[50px] mb-[20px] ff-cg--semibold text-center">
          What’s Included
        </h3>
        <div className="border solid rounded-2xl">
          {modules &&
            modules.map((item: any, index: number) => {
              return (
                <div
                  className={`flex p-[20px] ${
                    index === modules.length - 1 ? "" : "border-b solid"
                  }`}
                  key={index}
                >
                  <div className="w-full mb-4 lg:mb-0">
                    <h4 className="text-[16px] lg:text-[30px] ff-cg--bold text-white mb-[10px]">
                      {item.attributes.title}
                    </h4>
                    <p className="text-[18px] lg:text-[24px] ff-cg--light text-[#cccccc]">
                      {item.attributes.description}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );

  const common = (
    <>
      <section className="container bg-[#ffffff] px-[15px] mx-auto lg:pt-[40px] lg:pt-[60px] pb-[20px]">
        <div className="col-span-12 mb-[20px] lg:mb-[60px]">
          <div className="lg:flex relative lg:items-center lg:justify-between p-[15px] lg:px-[30px] lg:py-[40px]">
            <div className="mr-[50px]">
              <h3 className="ff-cg--bold text-[30px] lg:text-[80px] leading-none mb-6 lg:mb-8">
                Become a Data Analyst in 6 Months
              </h3>
            </div>
            <div className="w-full">
              <p className="text-base text-[18px] lg:text-[24px] leading-normal mb-6 lg:mb-8">
                Employers are increasingly seeking analysts with the skills to
                effectively problem-solve and communicate insights.
              </p>
              <p className="text-base text-[18px] lg:text-[24px] leading-normal">
                This bootcamp offers a unique approach, making sure you gain the
                fundamental business statistics concepts, analytics, and
                visualization tools. It will also teach you the strategic
                thinking required to become a successful analyst.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#222222] mb-[40px]">
        <div className="container px-[15px] lg:px-[60px] mx-auto pt-[30px] pb-[30px] lg:pb-[40px]">
          <h3 className="text-white text-[30px] lg:text-[50px] mb-[20px] ff-cg--semibold text-center">
            What’s Included
          </h3>
          <div className="border solid rounded-2xl">
            {modules &&
              modules.map((item: any, index: number) => {
                // return (
                //   <div
                //     className={`flex flex-col p-[20px] ${
                //       index === modules.length - 1 ? "" : "border-b solid"
                //     }`}
                //     key={index}
                //   >
                //     <h4 className="text-[16px] lg:text-[30px] ff-cg--semibold text-white mb-[10px]">
                //       {item.attributes.title}
                //     </h4>
                //     <div className="flex items-center flex-wrap">
                //       <span className="flex items-center text-[#fdbf38] border border-[#fdbf38] rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                //         <PlayIcon className="h-4 w-4 mr-[6px]" />
                //         <span className="ff-cg--semibold text-[12px]">
                //           {item.attributes.video} Videos
                //         </span>
                //       </span>
                //       <span className="flex items-center text-[#fdbf38] border border-[#fdbf38] rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                //         <FolderIcon className="h-4 w-4 mr-[6px]" />
                //         <span className="ff-cg--semibold text-[12px]">
                //           {item.attributes.resource} Resources
                //         </span>
                //       </span>
                //       <span className="flex items-center text-[#fdbf38] border border-[#fdbf38] rounded-full pl-[3px] pr-[10px] mr-[10px] mb-[10px]">
                //         <DocumentIcon className="h-4 w-4 mr-[6px]" />
                //         <span className="ff-cg--semibold text-[12px]">
                //           {item.attributes.quizzes} Quizzes
                //         </span>
                //       </span>
                //     </div>
                //     <p className="text-white">{item.attributes.description}</p>
                //   </div>
                // );
                return (
                  <div
                    className={`flex p-[20px] ${
                      index === modules.length - 1 ? "" : "border-b solid"
                    }`}
                    key={index}
                  >
                    <div className="w-full mb-4 lg:mb-0">
                      <h4 className="text-[16px] lg:text-[30px] ff-cg--bold text-white mb-[10px]">
                        {item.attributes.title}
                      </h4>
                      <p className="text-[18px] lg:text-[24px] ff-cg--light text-[#cccccc]">
                        {item.attributes.description}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );

  switch (sponsor?.title) {
    case "MindEdge":
      response = mindEdge;
      break;
    case "CertWise":
      response = certWise;
      break;
    case "MedCerts":
    case "Springboard":
      response = common;
      break;
    default:
      response = <></>;
  }

  return response;
};
